import React from "react";
import { Redirect, Route } from "react-router";
import { useAuthContext } from "../contexts/AuthContext";

const AuthRedirectRoute = ({ props }) => {
  const { currentUser } = useAuthContext();
  return (
    <Route
      {...props}
      render={() =>
        currentUser ? <Redirect to="/coachusers" /> : <Redirect to="/login" /> 
      }
    />
  );
};

export default AuthRedirectRoute;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Space,
  PageHeader,
  InputNumber,
  message,
  Select,
  Radio,
} from "antd";
import { Link } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import { useParams } from "react-router-dom";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import Container from "../../../../common/components/Container/Container";
import userTypes from "../../constants/userTypes";
import DeleteUserModal from "../../components/DeleteUserModal/DeleteUserModal";
import { useHistory } from "react-router-dom";
import useAthleteUser from "../../hooks/useAthleteUser";
import useCoachUser from "../../hooks/useCoachUser";

const { Option } = Select;

const AthleteUserDetail = () => {
  const [form] = Form.useForm();
  const { id: userId } = useParams();
  const { athleteUser } = useAthleteUser(userId);
  const { deleteCoachUser } = useCoachUser(userId);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const history = useHistory();

  const handleDeleteUser = async () => {
    setIsDeletingUser(true);
    try {
      await waitTime(2000);
      await deleteCoachUser(userId);
      message.success("User sucessfully deleted!");
    } catch (ex) {
      message.error("Could not delete user!");
    }
    setIsDeleteUserModalOpen(false);
    setIsDeletingUser(false);
    history.push("/athleteusers");
  };

  useEffect(() => {
    if (athleteUser) {
      form.setFieldsValue({
        firstName: athleteUser.firstName,
        lastName: athleteUser.lastName,
        email: athleteUser.email,
        sex: athleteUser.sex,
        address: athleteUser.address,
        address2: athleteUser.address2,
        city: athleteUser.city,
        state: athleteUser.state,
        country: athleteUser.country,
        sport: athleteUser.sport,
        position: athleteUser.position,
        parentalEmail: athleteUser.parentalEmail,
        parentalFirstName: athleteUser.parentalFirstName,
        parentalLastName: athleteUser.parentalLastName,
        parentalEmail: athleteUser.parentalEmail,
        schoolName: athleteUser.schoolName,
        gpa: athleteUser.gpa,
        satScore: athleteUser.satScore,
        actScore: athleteUser.actScore,
        canShareResults: athleteUser.canShareResults,
      });
    }
  }, [athleteUser]);

  return (
    <>
      <DeleteUserModal
        userEmail={athleteUser?.email}
        isVisible={isDeleteUserModalOpen}
        onCancel={() => setIsDeleteUserModalOpen(false)}
        onDeleteUser={handleDeleteUser}
        isDeletingUser={isDeletingUser}
      />
      <Container noPadding>
        <PageHeader
          className="site-page-header"
          title={"Athlete User Detail"}
        />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          onFinish={() => {}}
          requiredMark={true}
          layout={"vertical"}
          scrollToFirstError
        >
          <Form.Item name="firstName" label="First name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="lastName" label="Last name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="sex" label="Sex">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="canShareResults" label="Can Share Results">
            <Radio.Group>
              <Radio.Button value={false} disabled>
                No
              </Radio.Button>
              <Radio.Button value={true} disabled>
                Yes
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="address" label="Address">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="address2" label="Address2">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="city" label="City">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="state" label="State">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="country" label="Country">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="zipCode" label="Zip Code">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="sport" label="Sport">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="position" label="Position">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="parentalEmail" label="Parental Email">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="parentalFirstName" label="Parental First Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="parentalLastName" label="Parental Last Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="schoolName" label="School Name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="gpa" label="GPA">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="satScore" label="SAT Score">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="actScore" label="ACT Score">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Link to="/athleteusers">
                <Button type="secondary">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                {"Update"}
              </Button>
              <Button
                type="danger"
                loading={isDeletingUser}
                onClick={() => setIsDeleteUserModalOpen(true)}
              >
                Delete
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default AthleteUserDetail;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Space,
  PageHeader,
  InputNumber,
  message,
  Select,
  Radio,
} from "antd";
import { Link } from "react-router-dom";
import waitTime from "../../../../common/utils/waitTime";
import { useParams } from "react-router-dom";
import useFeatureFlags from "../../hooks/useFeatureFlags";
import Container from "../../../../common/components/Container/Container";
import userTypes from "../../constants/userTypes";
import DeleteUserModal from "../../components/DeleteUserModal/DeleteUserModal";
import { useHistory } from "react-router-dom";
import useCoachUser from "../../hooks/useCoachUser";

const { Option } = Select;

const CoachUserDetail = () => {
  const [form] = Form.useForm();
  const { id: userId } = useParams();
  const { coachUser, updateCoachUser, deleteCoachUser } = useCoachUser(userId);
  const { featureFlags } = useFeatureFlags();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const history = useHistory();

  const handleDeleteUser = async () => {
    setIsDeletingUser(true);
    try {
      await waitTime(2000);
      await deleteCoachUser(userId);
      message.success("User sucessfully deleted!");
    } catch (ex) {
      message.error("Could not delete user!");
    }
    setIsDeleteUserModalOpen(false);
    setIsDeletingUser(false);
    history.push("/coachusers");
  };

  useEffect(() => {
    if (coachUser) {
      form.setFieldsValue({
        firstName: coachUser.firstName,
        lastName: coachUser.lastName,
        email: coachUser.email,
        isVerified: coachUser.isVerified,
        athleteSpots: coachUser.athleteSpots,
        featureFlagIds: coachUser.featureFlags?.map((ff) => ff.id),
      });
    }
  }, [coachUser]);

  const handleUpdateUserSpots = async (values) => {
    setIsUpdating(true);
    await waitTime(2000);
    try {
      const updatedUser = {
        id: userId,
        athleteSpots: values.athleteSpots,
        featureFlagIds: values.featureFlagIds,
        isVerified: values.isVerified,
        userType: userTypes.headCoach,
      };
      await updateCoachUser(updatedUser);
      message.success("Successfully updated user!");
    } catch (err) {
      message.error("There was a problem updating the user!");
    }
    setIsUpdating(false);
  };

  return (
    <>
      <DeleteUserModal
        userEmail={coachUser?.email}
        isVisible={isDeleteUserModalOpen}
        onCancel={() => setIsDeleteUserModalOpen(false)}
        onDeleteUser={handleDeleteUser}
        isDeletingUser={isDeletingUser}
      />
      <Container noPadding>
        <PageHeader className="site-page-header" title={"Coach User Detail"} />
      </Container>
      <Container>
        <Form
          form={form}
          name="editUser"
          onFinish={handleUpdateUserSpots}
          requiredMark={false}
          layout={"vertical"}
          scrollToFirstError
        >
          <Form.Item name="firstName" label="First name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="lastName" label="Last name">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="athleteSpots" label="Roster Spots">
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="isVerified"
            label="Hudl verified"
            rules={[{ required: true, message: "Please pick a value" }]}
          >
            <Radio.Group>
              <Radio.Button value={false}>No</Radio.Button>
              <Radio.Button value={true}>Yes</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="featureFlagIds" label="Feature Flag(s)">
            <Select mode="multiple">
              {featureFlags &&
                featureFlags.map((featureFlag) => {
                  return (
                    <Option key={featureFlag.id} value={featureFlag.id}>
                      {featureFlag.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Link to="/coachusers">
                <Button type="secondary">Cancel</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                {"Update"}
              </Button>
              <Button
                type="danger"
                loading={isDeletingUser}
                onClick={() => setIsDeleteUserModalOpen(true)}
              >
                Delete
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default CoachUserDetail;

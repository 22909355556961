import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useCoachUsers = () => {
  const { data: coachUsers, error } = useSWR("/user", axios.get);
  const { mutate } = useSWRConfig();

  return {
    coachUsers,
    isLoading: !error && !coachUsers,
    isError: error,
  };
};

export default useCoachUsers;

import React, { useState, useEffect, useContext, createContext } from "react";
import * as authService from "../services/authService"

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = authService.onAuthChange(setCurrentUser, setIsLoading);
    return unsubscribe;
  }, []);

  const login = (email, password) => authService.login(email, password);

  const logOut = () => authService.logOut();

  return (
    <AuthContext.Provider value={{ currentUser, login, logOut }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };

import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useCoachUser = (id) => {
  const path = `/user/${id}`;
  const { data: coachUser, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

  const updateCoachUser = async (updatedUser) => {
    await axios.put(path, updatedUser);
    mutate(path);
    mutate("/user");
  };

  const deleteCoachUser = async (userId) => {
    await axios.delete(path);
    mutate(path);
    mutate("/user");
  };

  return {
    coachUser,
    isLoading: !error && !coachUser,
    isError: error,
    updateCoachUser,
    deleteCoachUser,
  };
};

export default useCoachUser;

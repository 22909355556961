import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useAthleteUser = (id) => {
  const path = `/athleteuser/${id}`;
  const { data: athleteUser, error } = useSWR(path, axios.get);
  const { mutate } = useSWRConfig();

//   const updateCoachUser = async (updatedUser) => {
//     await axios.put(path, updatedUser);
//     mutate(path);
//     mutate("/user");
//   };

//   const deleteCoachUser = async (userId) => {
//     await axios.delete(path);
//     mutate(path);
//     mutate("/user");
//   };

  return {
    athleteUser,
    isLoading: !error && !athleteUser,
    isError: error
  };
};

export default useAthleteUser;

import React, { useState } from "react";
import { Table, PageHeader, Input, Button, message } from "antd";
import Container from "../../../../common/components/Container/Container";
import styles from "./AthleteUsers.module.scss";
import { useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import waitTime from "../../../../common/utils/waitTime";
import useAthleteUsers from "../../hooks/useAthleteUsers";
import dayjs from "dayjs";

const { Search } = Input;

const AthleteUsers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { athleteUsers, isLoading } = useAthleteUsers();
  const history = useHistory();

  const filteredAthleteUsers = athleteUsers?.filter((athleteUser) =>
      athleteUser?.firstName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      athleteUser?.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    athleteUser.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: "First name",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Last name",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Date Joined",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (dateCreated) =>
        dayjs.utc(dateCreated).local().format("MM/DD/YYYY h:mm A"),
      sorter: true,
    },
    {
      title: "# Results",
      key: "numResults",
      dataIndex: "numResults",
    },
  ];

  return (
    <>
      <Container noPadding>
        <PageHeader title="Athlete Users" className="site-page-header" />
      </Container>
      <Container>
        <Search
          style={{ width: "100%" }}
          placeholder="Enter email, first name, etc..."
          allowClear
          onSearch={(query) => setSearchQuery(query)}
          enterButton
        />
      </Container>
      <Container>
        <Table
          rowKey={(user) => user.id}
          loading={isLoading}
          columns={columns}
          dataSource={filteredAthleteUsers}
          rowClassName={styles.tableRow}
          onRow={(user) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/athleteusers/${user.id}`);
              },
            };
          }}
        />
      </Container>
    </>
  );
};

export default AthleteUsers;

import AthleteUserDetail from "../pages/AthleteUserDetail/AthleteUserDetail";
import AthleteUsers from "../pages/AthleteUsers/AthleteUsers";
import CoachUserDetail from "../pages/CoachUserDetail/CoachUserDetail";
import CoachUsers from "../pages/CoachUsers/CoachUsers";

const userRoutes = [
  { path: "/coachusers", component: CoachUsers },
  { path: "/coachusers/:id", component: CoachUserDetail },
  { path: "/athleteusers", component: AthleteUsers },
  { path: "/athleteusers/:id", component: AthleteUserDetail },
];

export default userRoutes;

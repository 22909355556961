import useSWR, { useSWRConfig } from "swr";
import axios from "axios";

const useAthleteUsers = () => {
  const { data: athleteUsers, error } = useSWR("/athleteuser", axios.get);
  const { mutate } = useSWRConfig();

  return {
    athleteUsers,
    isLoading: !error && !athleteUsers,
    isError: error,
  };
};

export default useAthleteUsers;
